<template>
  <div id="app"></div>
<!--    <div :style="{backgroundColor:'#3F4B61',display:'flex',flexFlow:'wrap',alignContent:'start', paddingBottom:'16px', minHeight: '100vh',}">-->
<!--&lt;!&ndash;        <div class="iconItem" @click="jumpOldSystem">&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="../assets/images/business/dfxx.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="text">大富小学</div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/fjzx.png" alt="">-->
<!--            <div class="text">汾江中学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/qcxx.png" alt="">-->
<!--            <div class="text">奇槎小学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/xkcjnxx.png" alt="">-->
<!--            <div class="text">冼可澄纪念学校</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/zczxxx.png" alt="">-->
<!--            <div class="text">张槎中心小学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/lcxx.png" alt="">-->
<!--            <div class="text">黎涌小学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/swdyxx.png" alt="">-->
<!--            <div class="text">石湾第一小学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/scxx.png" alt="">-->
<!--            <div class="text">深村小学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/tjxx.png" alt="">-->
<!--            <div class="text">铁军小学</div>-->
<!--        </div>-->
<!--        <div class="iconItem" @click="jumpWechat">-->
<!--            <img src="../assets/images/business/znxx.png" alt="">-->
<!--            <div class="text">紫南小学</div>-->
<!--        </div>-->

<!--      &lt;!&ndash;银联支付渠道&ndash;&gt;-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/jjxx.png" alt="">-->
<!--        <div class="text">聚锦小学</div>-->
<!--      </div>-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/qzxx.png" alt="">-->
<!--        <div class="text">启智小学</div>-->
<!--      </div>-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/jsxx.png" alt="">-->
<!--        <div class="text">建设小学</div>-->
<!--      </div>-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/zxgxx.png" alt="">-->
<!--        <div class="text">周星拱小学</div>-->
<!--      </div>-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/common.png" alt="">-->
<!--        <div class="text">第二十五小学</div>-->
<!--      </div>-->

<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/logo_hyyry.png" alt="">-->
<!--        <div class="text">惠雅幼儿园</div>-->
<!--      </div>-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/logo_cjyy.png" alt="">-->
<!--        <div class="text">保利天珀幼儿园</div>-->
<!--      </div>-->
<!--      <div class="iconItem" @click="jumpWechat">-->
<!--        <img src="../assets/images/business/logo_pzez.png" alt="">-->
<!--        <div class="text">平洲二中</div>-->
<!--      </div>-->
<!--    </div>-->
</template>


<style scoped>
    .iconItem {
        margin-top: 16px;
        width: 33%;
        text-align: center;
    }
    .iconItem img {
        width: 65px;
    }
    .iconItem .text {
        color: #ffffff;
    }
</style>

<script>
    export default {
        data() {
            return {
                fullHeight: null
            };
        },
        mounted() {
            // this.fullHeight = document.documentElement.clientHeight + 'px';
          window.location.href = '/index';
        },
        methods: {
            jumpOldSystem() {
                window.location.href = 'https://hy.fsytss.com/index/school';
            },
            jumpWechat() {
                window.location.href = '/index';
            },
            jumpWait() {
                alert('该学校暂未开始缴费，敬请期待');
            }
        }
    };
</script>
